import React from "react"

import { Layout, Seo } from "../../components"
import { Link } from "gatsby"

const ContactSuccessPage = () => (
  <Layout>
    <Seo title="Formularz wysłany" />

    <div className="error-page-inner bg_color--4">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="inner">
              <h3 className="sub-title theme-gradient mb--20">
                Formularz został wysłany!
              </h3>
              <div className="error-button">
                <Link
                  className="rn-button-style--2 btn-solid"
                  to="/kontakt"
                  replace
                >
                  Powrót
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ContactSuccessPage
